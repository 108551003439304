import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import cn from 'classnames';
import styles from './DatePicker.module.scss';

import calendarIcon from '../../images/calendar.svg';

const getCorrectDayOfWeek = (day) => {
  const daysOfWeek = {
    0: 7,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
  };

  return daysOfWeek[day];
};

const Picker = ({
  day, setDay, month, setMonth, year, setYear,
}) => {
  const daysOfWeeks = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const currentDay = dayjs().year(year).month(month).date();
  const nextYear = dayjs().add(1, 'year').year();
  const firstDayOfMonth = useMemo(() => dayjs().year(year).month(month).date(1), [month, year]);
  const lastDayOfMonth = useMemo(() => firstDayOfMonth.add(1, 'month').subtract(1, 'day').date(), [month, year]);
  const firstDayOfMonthIndex = useMemo(() => getCorrectDayOfWeek(firstDayOfMonth.day()), [month, year]);

  const handleMonth = (e) => setMonth(e.target.value);
  const handleYear = (e) => setYear(e.target.value);
  const handleDayClick = (d) => {
    let { type } = d;
    if (d.type === 'past') {
      type = 'current';
      setMonth(month - 1);
    }
    if (d.type === 'next') {
      type = 'current';
      setMonth(month + 1);
    }
    setDay({ ...d, type });
  };

  const getMontArray = useMemo(() => {
    const allMonth = [];

    const getLastDayOfArray = (type) => {
      if (!allMonth.length) return 1;
      const lastWeekOfArray = allMonth[allMonth?.length - 1];
      if (type === 'type') {
        return lastWeekOfArray[lastWeekOfArray?.length - 1].type;
      }
      return lastWeekOfArray[lastWeekOfArray?.length - 1].day + 1;
    };

    const getFirstWeekDays = firstDayOfMonthIndex > 1 ? (7 - firstDayOfMonthIndex + 1) : 0;
    const getPlusWeeks = firstDayOfMonthIndex > 1 ? 1 : 0;

    const weeksCountOnMonth = Math.ceil(
      (lastDayOfMonth - getFirstWeekDays)
      / 7,
    ) + getPlusWeeks;

    for (let i = allMonth.length; i < weeksCountOnMonth; i += 1) {
      const currentWeek = [];

      for (let b = currentWeek.length, dayOfLastWeek = 0, dayOfFirstWeek = 0; b < 7; b += 1) {
        if (firstDayOfMonthIndex > 1 && !allMonth.length) {
          if (b + 1 < firstDayOfMonthIndex) {
            const cDay = firstDayOfMonth.subtract(firstDayOfMonthIndex - 1 - b, 'day').date();
            currentWeek.push({ day: cDay, type: 'past' });
          } else {
            dayOfFirstWeek += 1;
            currentWeek.push({ day: dayOfFirstWeek, type: 'current' });
          }
        } else if (!currentWeek.length) {
          currentWeek.push({ day: getLastDayOfArray(), type: getLastDayOfArray('type') });
        } else {
          const cDay = currentWeek[currentWeek.length - 1];
          const nextDay = cDay.day + 1;
          if (
            nextDay > lastDayOfMonth
            || cDay.type === 'next'
            || getLastDayOfArray('type') === 'next'
          ) {
            dayOfLastWeek += 1;
            currentWeek.push({ day: dayOfLastWeek, type: 'next' });
          } else {
            currentWeek.push({ day: nextDay, type: 'current' });
          }
        }
      }
      allMonth.push(currentWeek);
    }

    return allMonth;
  }, [month, year]);

  return (
    <div className={styles.picker}>
      <div className={styles.header}>
        <select
          name="months"
          id="months"
          className={styles.select}
          onChange={handleMonth}
          value={month}
        >
          <option value={0}>January</option>
          <option value={1}>February</option>
          <option value={2}>March</option>
          <option value={3}>April</option>
          <option value={4}>May</option>
          <option value={5}>June</option>
          <option value={6}>July</option>
          <option value={7}>August</option>
          <option value={8}>September</option>
          <option value={9}>October</option>
          <option value={10}>November</option>
          <option value={11}>December</option>
        </select>
        <select
          name="years"
          id="years"
          className={styles.select}
          onChange={handleYear}
        >
          <option value={currentYear}>{currentYear}</option>
          <option value={nextYear}>{nextYear}</option>
        </select>
      </div>
      <div className={styles.main}>
        <div className={styles.daysOfWeek}>
          {daysOfWeeks.map((daysOfWeek) => (
            <p key={daysOfWeek}>{daysOfWeek}</p>
          ))}
        </div>
        {getMontArray.map((week, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`numberOfWeek:${index}`}
            className={styles.days}
          >
            {week.map((date) => (
              <button
                key={`${week}${date.day}`}
                type="button"
                onClick={() => handleDayClick(date)}
                className={cn({
                  [styles.current]: currentDay === date.day && date.type === 'current' && month === currentMonth,
                  [styles.active]: day?.day === date.day && day?.type === date.type,
                  [styles.anotherMonth]: date.type !== 'current',
                })}
              >
                {date.day}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Picker.defaultProps = {
  day: null,
};

Picker.propTypes = {
  day: PropTypes.shape({
    day: PropTypes.number,
    type: PropTypes.string,
  }),
  setDay: PropTypes.func.isRequired,
  month: PropTypes.number.isRequired,
  setMonth: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  setYear: PropTypes.func.isRequired,
};

const DatePicker = ({ setDate, togglePicker, isPickerOpened }) => {
  const [month, setMonth] = useState(dayjs().month());
  const [year, setYear] = useState(dayjs().year());
  const [day, setDay] = useState(null);

  const date = useMemo(() => {
    const correctDay = day?.day || dayjs().date();
    const formattedMonth = dayjs().month(month).format('MM');
    const formattedDay = dayjs().date(correctDay).format('DD');
    return dayjs(`${year}-${formattedMonth}-${formattedDay}`).format('DD.MM.YYYY');
  }, [day, month, year]);

  useEffect(() => {
    setDate(date);
  }, [day, month, year]);

  return (
    <div className={styles.datePickerWrapper}>
      <button
        type="button"
        onClick={togglePicker}
        className={styles.button}
      >
        <img src={calendarIcon} alt="" />
        <p>
          {date}
          {' '}
          15:30
        </p>
      </button>

      {isPickerOpened && (
        <Picker
          day={day}
          setDay={setDay}
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
        />
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  setDate: () => {},
  togglePicker: () => {},
  isPickerOpened: false,
};

DatePicker.propTypes = {
  setDate: PropTypes.func,
  togglePicker: PropTypes.func,
  isPickerOpened: PropTypes.bool,
};

export default DatePicker;
