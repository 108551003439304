import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';

import './index.css';
import Main from './pages/Main';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

function LoginOrAppAndServiceWorker() {
  const [canUpdate, setCanUpdate] = useState(false);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: () => setCanUpdate(true),
    });
  }, []);

  return (
    <React.StrictMode>
      <Main canUpdate={canUpdate} />
    </React.StrictMode>
  );
}

ReactDOM.render(
  <LoginOrAppAndServiceWorker />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
