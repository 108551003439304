import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Main.module.scss';

import DatePicker from '../../components/DatePicker';

import plusBtnIcon from '../../images/plus.svg';
import logoutBtnIcon from '../../images/logout.svg';
import bearIcon from '../../images/bear.jpeg';

function Main() {
  const [date, setDate] = useState(null);
  const [isPickerOpened, setPickerOpen] = useState(false);

  const togglePicker = () => {
    setPickerOpen(!isPickerOpened);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    togglePicker();
    console.log({ date });
  };

  return (
    <div className={styles.mainPage}>
      <div className={styles.leftMenu}>
        <div className={styles.leftMenuProfiles}>
          <p className={styles.logo}>Beary Post</p>
          <button
            type="button"
            className={cn(styles.project, {
              [styles.isActive]: true,
            })}
          >
            <img src={bearIcon} alt="" />
          </button>
          <button
            type="button"
            className={cn(styles.project, {
              [styles.isActive]: false,
            })}
          >
            <img src={plusBtnIcon} alt="" />
          </button>
        </div>
        <button type="button" className={styles.logoutBtn}>
          <img src={logoutBtnIcon} alt="" />
        </button>
      </div>

      <main>
        <div className={styles.currentPostsContainer}>
          <h1>Current posts</h1>

          <div className={styles.emptyBlock}>
            <p>There are no pending posts yet</p>
          </div>
        </div>
        <div className={styles.newPostContainer}>
          <h1>New post</h1>

          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <input />
            </div>

            <form method="POST" className={styles.cardPost}>
              <DatePicker
                setDate={setDate}
                togglePicker={togglePicker}
                isPickerOpened={isPickerOpened}
              />

              <button
                type="submit"
                className={styles.submit}
                onClick={handleSubmit}
              >
                Beary post
              </button>
            </form>
          </div>
        </div>

        <h1 className={styles.projectName}>Beary Post</h1>
      </main>
    </div>
  );
}

export default Main;
